import { Manager } from "./Manager";
import { LoaderScene } from "./scenes/LoaderScene";

export const start = () => {
  // 設置好 pixi manager, 並且設置好畫面大小
  let width = window.innerWidth;
  let height = window.innerHeight;
  if (width > height) {
    const temp = width;
    width = height;
    height = temp;
  }
  Manager.initialize(width, height);

  // manager 轉到 loader scene, 過程會載入遊戲所有會用到的圖片資源
  Manager.changeScene(() => new LoaderScene());
};
