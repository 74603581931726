import { Container, Sprite, Texture, Graphics, Ticker } from "pixi.js";

import { FinishScene } from "./FinishScene";
import { Manager } from "../Manager";
import * as $ from "jquery";

declare global {
  interface Window {
    camera: any;
    renderer: any;
  }
}

export class GameScene extends Container {
  // 遊戲畫面的背景, 會根據 game mode 變化 texture
  private gameBg;

  // rvm canvas 的 sprite
  private canvas;
  // rvm canvas 會顯示在這裡
  private canvasTexture;

  private container;
  captureButton;
  flowers: any;
  flowerContainer: any;
  bear;
  flower2;
  flower1;
  switchButton;
  textures: any;
  canvasMask: any;
  canvasFilter: any;

  constructor() {
    super();

    $("#photo").attr(
      "src",
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg=="
    );
    $("#result").hide();
    $("#photo-container").hide();

    this.textures = [
      Texture.from("frame1"),
      Texture.from("frame2"),
      Texture.from("frame3"),
      Texture.from("frame4")
    ];

    this.sortableChildren = true;

    // container: 用來放影相畫面的 sprites
    this.container = new Container();
    this.container.sortableChildren = true;

    let headerHeight = 24;
    let footerHeight = 100;
    const tabBarHeight = 60;

    // 高度 = 螢幕高度 - 60 (header) - 170 (footer)
    let maskHeight = Manager.height - headerHeight - footerHeight;
    // 寬度 = 高度的 2/3
    let maskWidth = (maskHeight * 1241) / 2088;
    // 如果寬度超過螢幕寬度 - 6 (左右 margin)
    // 就把寬度設為螢幕寬度 - 6 - 6
    // 高度 = 寬度的 3/2
    if (maskWidth > Manager.width - 6 - 6) {
      maskWidth = Manager.width - 6 - 6;
      maskHeight = (maskWidth * 2088) / 1241;
    }
    // mask 的 y 座標 = 60 (header) + (螢幕高度 - 60 - 170 - maskHeight) / 2
    let maskY =
      headerHeight +
      (Manager.height - headerHeight - footerHeight - maskHeight) / 2;
    // mask 的 x 座標 = 6 (左 margin) + (螢幕寬度 - 6 - 6 - maskWidth) / 2
    let maskX = 6 + (Manager.width - 6 - 6 - maskWidth) / 2;

    // mask: 用來遮住不要的部分
    const mask = new Graphics();
    this.canvasMask = mask;
    mask.beginFill(0x333333);
    mask.drawRect(0, 0, maskWidth, maskHeight);
    mask.endFill();
    mask.y = maskY;
    mask.x = maskX;
    mask.zIndex = 1000;
    this.container.addChild(mask);

    // mask border: mask 的邊框
    const maskBorder = new Graphics();
    maskBorder.beginFill(0x000000);
    maskBorder.drawRect(0, 0, maskWidth + 3.6, maskHeight + 3.6);
    maskBorder.endFill();
    maskBorder.y = mask.y - 1.8;
    maskBorder.x = mask.x - 1.8;
    this.addChild(maskBorder);

    // canvas sprite: 放 camera canvas 的影像
    const canvas = document.getElementById("video");
    this.canvasTexture = Texture.from(canvas as any, {
      resolution: window.devicePixelRatio || 1
    });
    this.canvas = new Sprite(this.canvasTexture);
    this.canvas.zIndex = 2;
    this.canvas.anchor.set(0.5);
    this.canvas.x = Manager.width / 2;
    this.canvas.y = mask.y + maskHeight / 2;
    const ratio = Math.max(
      maskHeight / this.canvas.height,
      maskWidth / this.canvas.width
    );
    this.canvas.scale.set(ratio);
    const facingMode = window.camera.facingMode;
    if (facingMode === "user" && this.canvas.scale.x > 0) {
      this.canvas.scale.x = this.canvas.scale.x * -1;
    }
    if (facingMode === "environment" && this.canvas.scale.x < 0) {
      this.canvas.scale.x = this.canvas.scale.x * -1;
    }

    this.canvas.mask = mask;
    this.container.addChild(this.canvas);

    const rect1 = Sprite.from("rect1");
    rect1.zIndex = 10000;
    rect1.anchor.set(0, 1);
    rect1.x = Manager.width / 2 - 20 - 24 - 39 - 24 * 2;
    rect1.y = mask.y + mask.height;
    rect1.width = 50;
    rect1.height = 50;
    rect1.interactive = true;
    rect1.cursor = "pointer";
    rect1.on("pointerdown", () => {
      this.gameBg.texture = this.textures[0];
      rect1.alpha = 1;
      rect2.alpha = 0.5;
      rect3.alpha = 0.5;
      rect4.alpha = 0.5;
    });
    this.addChild(rect1);

    const rect2 = Sprite.from("rect2");
    rect2.zIndex = 10000;
    rect2.anchor.set(0, 1);
    rect2.x = Manager.width / 2 - 20 - 24;
    rect2.y = mask.y + mask.height;
    rect2.width = 50;
    rect2.height = 50;
    rect2.interactive = true;
    rect2.cursor = "pointer";
    rect2.on("pointerdown", () => {
      this.gameBg.texture = this.textures[1];
      rect1.alpha = 0.5;
      rect2.alpha = 1;
      rect3.alpha = 0.5;
      rect4.alpha = 0.5;
    });
    this.addChild(rect2);

    const rect3 = Sprite.from("rect3");
    rect3.zIndex = 10000;
    rect3.anchor.set(0, 1);
    rect3.x = Manager.width / 2 + 20 + 24;
    rect3.y = mask.y + mask.height;
    rect3.width = 50;
    rect3.height = 50;
    rect3.interactive = true;
    rect3.cursor = "pointer";
    rect3.on("pointerdown", () => {
      this.gameBg.texture = this.textures[2];
      rect1.alpha = 0.5;
      rect2.alpha = 0.5;
      rect3.alpha = 1;
      rect4.alpha = 0.5;
    });
    this.addChild(rect3);

    const rect4 = Sprite.from("rect4");
    rect4.zIndex = 10000;
    rect4.anchor.set(0, 1);
    rect4.x = Manager.width / 2 + 20 + 24 + 39 + 24 * 2;
    rect4.y = mask.y + mask.height;
    rect4.width = 50;
    rect4.height = 50;
    rect4.interactive = true;
    rect4.cursor = "pointer";
    rect4.on("pointerdown", () => {
      this.gameBg.texture = this.textures[3];
      rect1.alpha = 0.5;
      rect2.alpha = 0.5;
      rect3.alpha = 0.5;
      rect4.alpha = 1;
    });
    this.addChild(rect4);

    rect1.alpha = 1;
    rect2.alpha = 0.5;
    rect3.alpha = 0.5;
    rect4.alpha = 0.5;

    // capture button: 拍照按鈕
    this.captureButton = Sprite.from("camera");
    this.captureButton.zIndex = 10000;
    this.captureButton.anchor.set(0.5, 1);
    this.captureButton.x = Manager.width / 2;
    this.captureButton.y = Manager.height - 15;
    this.captureButton.width = 66;
    this.captureButton.height = 66;
    this.captureButton.interactive = true;
    this.captureButton.cursor = "pointer";
    let isCapturing = false;
    this.captureButton.on("pointerdown", () => {
      if (isCapturing) return;
      isCapturing = true;

      setTimeout(() => {
        this.capture();
      }, 600);
    });
    this.addChild(this.captureButton);

    // switch button: 切換前後鏡頭
    this.switchButton = Sprite.from("switch");
    this.switchButton.zIndex = 10000;
    this.switchButton.anchor.set(0.5, 1);
    this.switchButton.x = Manager.width / 2 + 120;
    this.switchButton.y = Manager.height - 25;
    this.switchButton.width = 48;
    this.switchButton.height = 48;
    this.switchButton.interactive = true;
    this.switchButton.cursor = "pointer";
    let isSwitching = false;
    this.switchButton.on("pointerdown", () => {
      if (isSwitching) return;
      isSwitching = true;
      window.camera.switch(this.canvas).then(() => {
        // const facingMode = window.camera.facingMode;
        // if (facingMode === "user" && this.canvas.scale.x > 0) {
        //   this.canvas.scale.x = this.canvas.scale.x * -1;
        // }
        // if (facingMode === "environment" && this.canvas.scale.x < 0) {
        //   this.canvas.scale.x = this.canvas.scale.x * -1;
        // }
        isSwitching = false;
      });
    });
    this.addChild(this.switchButton);

    // 遊戲背景 sprite
    this.gameBg = new Sprite(this.textures[0]);
    this.gameBg.zIndex = 4000;
    this.gameBg.x = mask.x;
    this.gameBg.y = mask.y;
    this.gameBg.width = maskWidth;
    this.gameBg.height = maskHeight;
    this.container.addChild(this.gameBg);

    const dx = mask.x;
    const dy = mask.y;
    mask.x -= dx;
    mask.y -= dy;
    this.gameBg.x -= dx;
    this.gameBg.y -= dy;
    this.canvas.x -= dx;
    this.canvas.y -= dy;
    this.container.x = dx;
    this.container.y = dy;

    const newHeight = this.container.height - tabBarHeight;
    const originalWidth = this.container.width;
    const newScale = newHeight / this.container.height;
    this.container.scale.set(newScale);
    const newWidth = this.container.width;
    this.container.x -= (newWidth - originalWidth) / 2;

    const gap = (newWidth - 50 - 50 - 50 - 50) / 3;
    rect1.x = (Manager.width - newWidth) / 2;
    rect2.x = rect1.x + 50 + gap;
    rect3.x = rect2.x + 50 + gap;
    rect4.x = rect3.x + 50 + gap;

    maskBorder.scale.set(newScale);
    maskBorder.x -= (newWidth - originalWidth) / 2;

    this.addChild(this.container);

    // const filter = new PIXI.filters.OutlineFilter(1.8, 0x000000, 1);
    // filter.resolution = window.devicePixelRatio || 1;
    // this.container.filters = [filter];
  }

  // 拍照
  private async capture() {
    const newContainer = new Container();
    newContainer.sortableChildren = true;
    const clonedGameBg = new Sprite(this.gameBg.texture);
    clonedGameBg.position.copyFrom(this.gameBg.position);
    clonedGameBg.scale.copyFrom(this.gameBg.scale);
    clonedGameBg.anchor.copyFrom(this.gameBg.anchor);
    clonedGameBg.zIndex = 1002;
    // console.log("clonedGameBg", clonedGameBg.width, clonedGameBg.height);
    newContainer.addChild(clonedGameBg);
    const clonedCanvas = new Sprite(this.canvas.texture);
    clonedCanvas.position.copyFrom(this.canvas.position);
    clonedCanvas.scale.copyFrom(this.canvas.scale);
    clonedCanvas.anchor.copyFrom(this.canvas.anchor);
    clonedCanvas.zIndex = 1000;
    // console.log("clonedCanvas", clonedCanvas.width, clonedCanvas.height);
    newContainer.addChild(clonedCanvas);
    const clonedMask = this.canvasMask.clone();
    clonedMask.zIndex = 1001;
    // console.log("clonedMask", clonedMask.width, clonedMask.height);
    newContainer.addChild(clonedMask);
    // clonedCanvas.filters = [this.canvasFilter];
    clonedCanvas.mask = clonedMask;
    // newContainer.x = 0;
    newContainer.y = -10000;
    this.addChild(newContainer);

    // const clonedContainer = cloneContainer(this.container);
    // clonedContainer.x = 0;
    // clonedContainer.y = 0;
    // this.addChild(clonedContainer);
    // console.log("newContainer", newContainer.width, newContainer.height);
    const scale = 1440 / this.gameBg.width;
    // console.log("this.canvasMask.width", this.gameBg.width);
    // console.log("scale", scale);
    // clonedGameBg.width = scale * clonedGameBg.width;
    // clonedGameBg.height = scale * clonedGameBg.height;
    // clonedCanvas.width = scale * clonedCanvas.width;
    // clonedCanvas.height = scale * clonedCanvas.height;
    // clonedMask.width = scale * clonedMask.width;
    // clonedMask.height = scale * clonedMask.height;

    // const ratio = Math.max(
    //   clonedMask.height / clonedCanvas.height,
    //   clonedMask.width / clonedCanvas.width
    // );
    // clonedCanvas.width = clonedCanvas.width * ratio;
    // clonedCanvas.height = clonedCanvas.height * ratio;
    // clonedCanvas.x = -(clonedCanvas.width - clonedMask.width) / 2;
    // clonedCanvas.y = -(clonedCanvas.height - clonedMask.height) / 2;

    // if (this.canvas.scale.x < 0) {
    //   clonedCanvas.scale.x = clonedCanvas.scale.x * -1;
    //   clonedCanvas.x += clonedCanvas.width;
    // }
    // console.log("clonedGameBg", clonedGameBg.width, clonedGameBg.height);
    // console.log("newContainer", newContainer.width, newContainer.height);
    newContainer.scale.set(scale);
    // console.log("newContainer", newContainer.width, newContainer.height);

    Ticker.shared.addOnce(async () => {
      console.log("Sprite has been scaled and rendered at new size");
      const texture = await Manager.app.renderer.generateTexture(newContainer, {
        region: newContainer
        // multisample: PIXI.MSAA_QUALITY.NONE
        // resolution: window.devicePixelRatio || 1
      });
      const c = await Manager.app.renderer.extract.canvas(texture);
      const image = c.toDataURL("image/jpeg", 0.9);
      // let blob = dataURLToBlob(image);
      // let url = URL.createObjectURL(blob);
      texture.destroy(true);
      window.dataUrl = image;

      // $("#pixi-content").show();

      Manager.changeScene(() => new FinishScene());
    });

    // newContainer.width = 1440;
    // newContainer.height = 2160;
    // $("#pixi-content").hide();

    // // this.container.scale.set(1440 / this.container.width);
    // // this.this.container.x = 0;
    // setTimeout(async () => {
    // const texture = await Manager.app.renderer.generateTexture(newContainer, {
    //   region: newContainer
    //   // multisample: PIXI.MSAA_QUALITY.NONE
    //   // resolution: window.devicePixelRatio || 1
    // });
    // const c = await Manager.app.renderer.extract.canvas(texture);
    // const image = c.toDataURL();
    // texture.destroy(true);
    // $("#result-img").attr("src", image);
    // window.dataUrl = image;

    // // $("#pixi-content").show();

    // Manager.changeScene(() => new FinishScene());
    // }, 200);
  }

  //
  public update(framesPassed: number): void {
    // canvas texture 更新動畫
    this.canvasTexture.update();
  }

  public resize(screenWidth: number, screenHeight: number): void {}
}

function dataURLToBlob(dataURL) {
  let parts = dataURL.split(";base64,");
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;
  let uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}
