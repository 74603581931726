import * as $ from "jquery";

export class Camera {
  constructor() {
    this.video = document.getElementById("video");
    this.facingMode = "user";
  }

  static async setup() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      throw new Error(
        "Browser API navigator.mediaDevices.getUserMedia not available"
      );
    }

    const videoConfig = {
      audio: false,
      video: {
        facingMode: "user",
        width: {
          ideal: 1920
        },
        height: {
          ideal: 1080
        },
        frameRate: {
          ideal: 24
        }
      }
    };

    // webcam stream
    const stream = await navigator.mediaDevices.getUserMedia(videoConfig);
    window.stream = stream;

    // 將 camera 的畫面放到 video
    const camera = new Camera();
    camera.video.srcObject = stream;

    camera.video.play().then(() => {
      camera.video.pause();
    });

    // 等待 video 載入完成
    const promise = new Promise(resolve => {
      camera.video.onloadedmetadata = () => {
        resolve(true);
      };
    });
    const timeout = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Timeout"));
      }, 5000);
    });
    try {
      await Promise.race([promise, timeout]);
    } catch (error) {
      throw new Error("Timeout");
    }

    await new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 500);
    });

    // 播放 video
    camera.video.play();

    const videoWidth = camera.video.videoWidth;
    const videoHeight = camera.video.videoHeight;

    // 設置 video 的高度和寬度
    camera.video.width = videoWidth;
    camera.video.height = videoHeight;

    // document.addEventListener("visibilitychange", async () => {
    //   if (document.hidden) {
    //     // User switched to another tab, stop all video tracks.
    //     if (window.stream) {
    //       window.stream.getVideoTracks().forEach(track => {
    //         track.stop();
    //       });
    //     }
    //   } else {
    //     // User switched back to this tab, start video tracks again.
    //     if (window.stream) {
    //       window.stream.getVideoTracks().forEach(track => {
    //         track.stop();
    //       });
    //     }

    //     const videoConfig = {
    //       audio: false,
    //       video: {
    //         facingMode: {
    //           ideal: camera.facingMode
    //         },
    //         width: {
    //           ideal: 3840
    //         },
    //         height: {
    //           ideal: 2160
    //         },
    //         frameRate: {
    //           ideal: 24
    //         }
    //       }
    //     };

    //     // webcam stream
    //     const stream = await navigator.mediaDevices.getUserMedia(videoConfig);
    //     window.stream = stream;

    //     // 切换到新的媒体流
    //     camera.video.srcObject = stream;

    //     // 等待 video 載入完成
    //     await new Promise(resolve => {
    //       camera.video.onloadedmetadata = () => {
    //         resolve(true);
    //       };
    //     });

    //     // 播放 video
    //     camera.video.play();

    //     const videoWidth = camera.video.videoWidth;
    //     const videoHeight = camera.video.videoHeight;

    //     // 設置 video 的高度和寬度
    //     camera.video.width = videoWidth;
    //     camera.video.height = videoHeight;
    //   }
    // });

    // 返回 camera instance
    return camera;
  }

  async switch(canvas) {
    try {
      this.facingMode = this.facingMode === "user" ? "environment" : "user";

      if (window.stream) {
        window.stream.getTracks().forEach(track => {
          track.stop();
        });
      }

      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error(
          "Browser API navigator.mediaDevices.getUserMedia not available"
        );
      }

      const videoConfig = {
        audio: false,
        video: {
          facingMode: {
            ideal: this.facingMode
          },
          width: {
            ideal: 1920
          },
          height: {
            ideal: 1080
          },
          frameRate: {
            ideal: 24
          }
        }
      };

      // webcam stream
      const stream = await navigator.mediaDevices.getUserMedia(videoConfig);
      window.stream = stream;

      // 切换到新的媒体流
      this.video.srcObject = stream;

      this.video.play().then(() => {
        this.video.pause();
      });

      // 等待 video 載入完成
      const promise = new Promise(resolve => {
        camera.video.onloadedmetadata = () => {
          resolve(true);
        };
      });
      const timeout = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Timeout"));
        }, 5000);
      });
      try {
        await Promise.race([promise, timeout]);
      } catch (error) {
        throw new Error("Timeout");
      }

      const facingMode = this.facingMode;
      if (facingMode === "user" && canvas.scale.x > 0) {
        canvas.scale.x = canvas.scale.x * -1;
      }
      if (facingMode === "environment" && canvas.scale.x < 0) {
        canvas.scale.x = canvas.scale.x * -1;
      }

      await new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });

      // 播放 video
      this.video.play();

      const videoWidth = this.video.videoWidth;
      const videoHeight = this.video.videoHeight;

      // 設置 video 的高度和寬度
      this.video.width = videoWidth;
      this.video.height = videoHeight;
    } catch (error) {
      console.log(err);
      if (err.name === "NotAllowedError") {
        $("#wrong-permission").show();
        $("#main").hide();
      } else if (
        err.message ===
          "Browser API navigator.mediaDevices.getUserMedia not available" ||
        err.message === "Timeout"
      ) {
        $("#wrong-camera").show();
        $("#main").hide();
      }
    }
  }
}
