import { Camera } from "./pose/camera";
import * as game from "./game";
import { Manager } from "./game/Manager";
import { GameScene } from "./game/scenes/GameScene";
import * as $ from "jquery";

// camera instance
let camera;

// // pose renderer
// let renderer = null;

// // pose canvas
// let canvas;

async function renderResult() {
  // 確保 camera 正常
  if (camera.video.readyState < 2) {
    await new Promise(resolve => {
      camera.video.onloadeddata = () => {
        resolve(true);
      };
    });
  }

  // // 將姿勢估計結果畫到 pose canvas 上
  // renderer.draw([camera.video]);
}

async function renderPrediction() {
  await renderResult();

  requestAnimationFrame(renderPrediction);
}

let isClicked = false;

// app 入口
async function start() {
  // 設置 camera
  window.camera = camera = await Camera.setup();

  // // 設置 pose canvas
  // canvas = document.getElementById("output");
  // canvas.width = camera.video.width;
  // canvas.height = camera.video.height;

  // // 設置 pose renderer 到 pose canvas
  // window.renderer = renderer = new RendererCanvas2d(canvas, { flip: true });

  // 設置遊戲, pixi js 開始產生 canvas 到指定大小
  game.start();

  // 開始 render loop
  renderPrediction();
}

// app();

// cache-control: max-age=0, must-revalidate
window.onpageshow = function(event) {
  if (event.persisted) {
    window.location.reload();
  }
};

setInterval(() => {
  if (window.innerHeight !== $("body").height()) {
    // $("body").height(window.innerHeight);
    $(":root").css({ "--height": window.innerHeight + "px" });
  }
  if (Manager.app && Manager.app.renderer.height !== window.innerHeight) {
    Manager.app.renderer.resize(window.innerWidth, window.innerHeight);
  }
}, 200);

const urlParams = new URLSearchParams(window.location.search);
const debug = urlParams.get("debug");
if (debug) {
  setTimeout(() => {
    $("#start-btn").trigger("click");
  }, 200);
}

setTimeout(() => {
  $("#start-btn").on("click", function() {
    if (isClicked) return;
    isClicked = true;

    $("#start-btn").css("opacity", "0.5");
    setTimeout(() => {
      start()
        .then(() => {
          $("#intro").hide();
          $("#pixi-content").show();

          isClicked = false;
        })
        .catch(err => {
          console.log(err);
          if (err.name === "NotAllowedError") {
            $("#wrong-permission").show();
            $("#main").hide();
          } else if (
            err.message ===
              "Browser API navigator.mediaDevices.getUserMedia not available" ||
            err.message === "Timeout"
          ) {
            $("#wrong-camera").show();
            $("#main").hide();
          }
        });
    }, 200);
  });

  $("#retake-btn").on("click", function() {
    Manager.changeScene(() => new GameScene());
  });

  // if (!navigator.share) {
  //   $("#share-btn").hide();
  // }
  $("#share-btn").on("click", async function() {
    // const blob = await compressAndConvertDataURLToBlob(window.dataUrl, 0.9);

    // const filesArray = [
    //   new File([blob], "photo.png", {
    //     type: blob.type,
    //     lastModified: new Date().getTime()
    //   })
    // ];
    // const shareData = {
    //   files: filesArray
    // };
    // navigator.share(shareData);

    // // 創建一個 DOM 元素 <a>
    // var downloadLink = document.createElement("a");
    // downloadLink.href = window.dataUrl;
    // downloadLink.download = "photo.png"; // 指定下載的文件名和格式

    // // 觸發下載
    // downloadLink.click();

    var blob = dataURLToBlob(window.dataUrl);
    var url = URL.createObjectURL(blob);

    var downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `image_${Date.now()}.jpg`;

    // 触发下载
    downloadLink.click();

    // 清理
    setTimeout(function() {
      window.URL.revokeObjectURL(url);
    }, 3000);
  });

  $("#intro").show();
}, 200);

function dataURLToBlob(dataUrl) {
  var parts = dataUrl.split(";base64,");
  var contentType = parts[0].split(":")[1];
  var raw = window.atob(parts[1]);
  var rawLength = raw.length;
  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  console.log("dataURLToBlob", contentType);

  return new Blob([uInt8Array], { type: contentType });
}

document.addEventListener(
  "touchstart",
  function(event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  },
  { passive: false }
);

function compressAndConvertDataURLToBlob(dataURL, quality) {
  return new Promise((resolve, reject) => {
    // 建立新的 Image 物件
    let img = new Image();
    img.src = dataURL;
    img.onload = function() {
      // 建立 canvas 元素
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      // 將圖片繪製到 canvas 上
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;
      ctx.drawImage(this, 0, 0, this.naturalWidth, this.naturalHeight);

      // 壓縮圖片並將其轉換為 blob
      canvas.toBlob(blob => resolve(blob), "image/jpeg", quality);
    };
    img.onerror = function() {
      reject(new Error("Error in compressing image"));
    };
  });
}

var img = document.getElementById("photo");
var userAgent = navigator.userAgent.toLowerCase();
var isAndroid = userAgent.indexOf("android") > -1;
var isIOS = /(ipad|iphone|ipod)/i.test(userAgent);

if (isAndroid) {
  img.addEventListener("contextmenu", function(event) {
    event.preventDefault();
    var link = document.createElement("a");
    link.href = img.src;
    link.download = `image_${new Date().toLocaleTimeString()}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });

  img.addEventListener("click", function(event) {
    event.preventDefault();
    var link = document.createElement("a");
    link.href = window.dataUrl;
    link.download = `image_${new Date().toLocaleTimeString()}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}
