export const manifest = {
  bundles: [
    {
      name: "bundleName",
      assets: {
        camera:
          "https://afzn-photobooth.vercel.app/static/afzn/camera-capture.png",
        switch:
          "https://afzn-photobooth.vercel.app/static/afzn/camera-rotate.png",
        frame1: "https://afzn-photobooth.vercel.app/static/afzn/frame1.png",
        frame2: "https://afzn-photobooth.vercel.app/static/afzn/frame2.png",
        frame3: "https://afzn-photobooth.vercel.app/static/afzn/frame3.png",
        frame4: "https://afzn-photobooth.vercel.app/static/afzn/frame4.png",
        rect1: "https://afzn-photobooth.vercel.app/static/afzn/rect1.png",
        rect2: "https://afzn-photobooth.vercel.app/static/afzn/rect2.png",
        rect3: "https://afzn-photobooth.vercel.app/static/afzn/rect3.png",
        rect4: "https://afzn-photobooth.vercel.app/static/afzn/rect4.png"
      }
    }
  ]
};
