import { Container, Graphics } from "pixi.js";

import { Manager } from "../Manager";
import * as $ from "jquery";

declare global {
  interface Window {
    camera: any;
    renderer: any;
  }
}

export class FinishScene extends Container {
  // 遊戲畫面的背景, 會根據 game mode 變化 texture
  private gameBg;

  // rvm canvas 的 sprite
  private canvas;
  // rvm canvas 會顯示在這裡
  private canvasTexture;

  private container;
  captureButton;
  flowers: any;
  flowerContainer: any;
  bear;
  flower2;
  flower1;
  switchButton;
  textures: any;
  canvasMask: any;
  canvasFilter: any;
  filename: string;
  file;
  imageTexture: any;
  imageSprite: any;
  imageTexture2: any;
  imageSprite2: any;
  maskHeight: number;
  maskWidth: number;
  maskY: number;
  maskX: number;
  long: any;
  retake: any;
  print: any;

  constructor() {
    super();

    this.sortableChildren = true;

    let headerHeight = 24;
    let footerHeight = 128 - 36;
    const tabBarHeight = 60;

    // 高度 = 螢幕高度 - 60 (header) - 170 (footer)
    let maskHeight = Manager.height - headerHeight - footerHeight;
    // 寬度 = 高度的 2/3
    let maskWidth = (maskHeight * 1241) / 2088;
    if (maskWidth > Manager.width - 6 - 6) {
      maskWidth = Manager.width - 6 - 6;
      maskHeight = (maskWidth * 2088) / 1241;
    }
    // alert("maskHeight:" + maskHeight);
    let maskY =
      headerHeight +
      (Manager.height - headerHeight - footerHeight - maskHeight) / 2;
    let maskX = 6 + (Manager.width - 6 - 6 - maskWidth) / 2;
    this.maskHeight = maskHeight;
    this.maskWidth = maskWidth;
    this.maskY = maskY;
    this.maskX = maskX;

    const maskBorder = new Graphics();
    maskBorder.beginFill(0x000000);
    maskBorder.drawRect(0, 0, maskWidth + 3.6, maskHeight + 3.6);
    maskBorder.endFill();
    maskBorder.y = maskY - 1.8;
    maskBorder.x = maskX - 1.8;
    maskBorder.alpha = 0.25;
    this.addChild(maskBorder);

    this.action();
  }

  public async action() {
    $("#result").show();
    $("#photo-container").show();
    $("#photo-container").css({
      top: this.maskY,
      left: this.maskX,
      width: this.maskWidth,
      height: this.maskHeight
    });
    $("#photo").hide();
    $("#photo").attr("src", window.dataUrl);
    $("#photo").show();
  }

  public update(framesPassed: number): void {}

  public resize(screenWidth: number, screenHeight: number): void {}
}
